<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Resumen del Documento" :breadcrumbs="breadcrumbs" :breadcrumbsItemDinamic="{ loading: loadingDetail, text: `${activeDocument?.name} Nº ${instance.number}`}" :dense="true" :hiddenTab="true" :addPadding="true" :scroll="scroll">
      <template v-slot:main>
        <v-btn class="mr-2" @click="retrieve" outlined>
          <v-img contain :src="require(`@/assets/icon-update${$vuetify.theme.dark ? '-dark' : ''}.svg`)" />
        </v-btn>
        <v-menu v-if="$vuetify.breakpoint.width <= 1920" close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined v-bind="attrs" v-on="on">
              <v-icon left>mdi-plus-box </v-icon>Opciones<v-icon size="18" right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0">
            <v-list-item class="px-3" @click="downloadFile2('documents', `${instance.type}_${instance.number}`)" :ripple="false">
              <v-list-item-title class="body-1 grey-500--text">Descargar documento</v-list-item-title>
            </v-list-item>
            <v-list-item class="px-3" v-if="['APR', 'OBS'].includes(instance.status) && $store?.state?.auth?.account?.country !== 'CL'" @click="downloadFile2('documents', `${instance.type}_${instance.number}`, null, 'voucher')" :ripple="false">
              <v-list-item-title class="body-1 grey-500--text">Descargar CDR</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item class="px-3" v-if="['PE31', 'PE09'].includes((instance || {}).type || '')" @click="dialogMarkAsAnulled=true" :disabled="!instance.status || instance.status === 'VOI'" :ripple="false">
              <v-list-item-title class="body-1 grey-500--text">Marcar como nulo</v-list-item-title>
            </v-list-item> -->
            <v-list-item class="px-3" v-if="['FAI', 'SNT', 'REJ', 'DUP'].includes(instance.status)" @click="dialogReprocessDoc=true" :ripple="false">
              <v-list-item-title class="body-1 grey-500--text">Re-procesar el documento</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <template v-else>
          <v-btn class="mx-2" v-if="['FAI', 'SNT', 'REJ', 'DUP'].includes(instance.status)" @click="dialogReprocessDoc=true" outlined><v-icon left>mdi-file-refresh</v-icon>Re-procesar el documento</v-btn>
          <v-btn v-if="['APR', 'OBS'].includes(instance.status)" @click="createExport" class="mr-2" :disabled="loadingDetail" outlined><v-icon left>mdi-download</v-icon>Descargar CDR</v-btn>
          <v-btn @click="downloadFile2('documents', `${instance.type}_${instance.number}`)" :disabled="loadingDetail" outlined><v-icon left>mdi-download</v-icon>Descargar documento</v-btn>
        </template>
        <v-btn class="mx-2" @click="modalEmail=true" outlined><v-icon left>mdi-email</v-icon>Enviar por mail</v-btn>
        <v-btn color="blue-500" @click="modalPDF=true"><v-icon left>mdi-text-box</v-icon>Ver PDF</v-btn>
      </template>
    </mini-header>
    <!-- end header -->

    <v-col class="px-0" style="z-index: 0;">
      <skeleton-detail v-if="loadingDetail" />
      <div class="d-flex" v-else>
        <!-- detail card -->
        <div class="mr-6" style="min-width: 400px;">
          <div style="position:fixed!important;">
            <v-img contain :width="400" :height="110" :src="require(`@/assets/backgrounds/background-document-detail.svg`)">
              <span class="px-4 pt-4 subtitle-2 white--text font-weight-bold d-inline-block text-truncate" style="max-width: 300px;">
                {{instance?.type ?? '' | nameDocumentsType}} Nº{{instance.number}}
              </span>
              <v-row class="ml-2 mt-3" align="end" no-gutters>
                <v-col cols="5" class="py-0 px-2">
                  <span class="d-block body-1 white--text">Total</span>
                  <span class="subtitle-2 white--text">
                    <span class="subtitle-2 white--text">
                      <span class="font-weight-bold">{{instance.amount_total}}</span>
                      <span class="caption">{{instance?.currency || $store?.state?.auth?.account?.base_currency?.id}}</span>
                    </span>
                  </span>
                </v-col>
                <v-col cols="5" class="py-0 px-2">
                  <span class="d-block body-1 white--text">Fecha</span>
                  <span class="subtitle-2 white--text">
                    <span class="font-weight-bold">{{instance.date | date}}</span>
                  </span>
                </v-col>
              </v-row>
            </v-img>
            <v-card class="pa-0 mt-4" width="400" transition="slide-y-transition" min-height="calc(100% - 285px)" flat style="position:fixed!important;">
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-bold">Resumen del documento</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="overflow-y-auto pa-0" :style="$vuetify.breakpoint.height <= 700 ? 'max-height: calc(100vh - 353px)' : 'max-height: calc(100vh - 300px)'">
              <v-divider />
              <!-- detail -->
              <div class="py-4 px-5">
                <v-list class="v-list-form-main transparent pa-0">
                  <v-list-item class="pa-0">
                    <v-list-item-title class="grey-500--text subtitle-2">Razón Social</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{(instance || {}).customer_name }}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="grey-500--text subtitle-2">{{$t('generals.RUT')}}</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{ (instance || {}).customer_tax_id }}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="grey-500--text subtitle-2">Tipo de documento</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{((instance || {}).type || '') | nameDocumentsType}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0" v-if="instance.status !== 'draft'">
                    <v-list-item-title class="grey-500--text subtitle-2">{{$t('generals.Folio')}}</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{instance.number}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="grey-500--text subtitle-2">ID</v-list-item-title>
                    <v-list-item-subtitle class="body-1">
                      <v-tooltip color="grey-background" right transition="scale-transition" max-width="300">
                        <template v-slot:activator="{on}">
                          <v-btn class="grey-300--text" @click="toClipboard(instance.id)" text v-on="on" :ripple="false">{{instance.id}}</v-btn>
                        </template>
                        <span class="d-block px-3 py-2">Copiar</span>
                      </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="grey-500--text subtitle-2">Creado el</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{instance.created | dateTimeSecond}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0" v-if="instance.summary_id">
                    <v-list-item-title class="grey-500--text subtitle-2">{{ ['PE20', 'P40'].includes(instance.type) ? 'Reversión' : 'Baja' }}</v-list-item-title>
                    <v-list-item-subtitle class="body-1">
                      <router-link :to="{name: 'SummariesRetrieve', params: {id: instance.summary_id, ...currentParams} }" class="d-flex align-center blue-500--text text-decoration-none">
                        {{instance.summary_id}}
                        <v-icon right color="blue-500" size="14">mdi-open-in-new</v-icon>
                      </router-link>
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </div>
              <!-- end detail -->

              <!-- totals -->
              <v-col class="px-5">
                <v-row no-gutters align="center" justify="space-between">
                  <v-col class="pa-0">
                    <span class="ml-n1 subtitle-2 grey-700--text font-weight-bold"><v-icon size="18" left color="grey-700">mdi-currency-usd</v-icon>Totales</span>
                  </v-col>
                </v-row>
                <v-divider class="my-3" />
                <v-list class="transparent pa-0" width="100%">
                  <v-list-item>
                    <v-list-item-title class="subtitle-2">Subtotal</v-list-item-title>
                    <v-list-item-subtitle class="text-right font-weight-bold">{{instance.amount_subtotal | currency(((instance || {}).type || ''))}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title class="subtitle-2">Descuento</v-list-item-title>
                    <v-list-item-subtitle class="text-right grey-500--text font-weight-bold">
                      <template v-if="(instance || {}).amount_discount">
                        {{instance.amount_discount | number}}
                        <template v-if="instance.discount_scheme === '%'">{{instance.discount_scheme}}</template>
                        <template v-if="instance.discount_scheme === '$'">{{((instance || {}).type || '') | firstDocumentsTypeCurrency}}</template>
                      </template>
                      <template v-else>-</template>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <template v-if="(activeDocument || {}).has_taxes_detail">
                    <v-list-item class="mt-3">
                      <v-list-item-title class="subtitle-2">Exento</v-list-item-title>
                      <v-list-item-subtitle class="text-right grey-500--text font-weight-bold">{{instance?.amount_exempt ?? 0 | currency(((instance || {}).type || ''))}}</v-list-item-subtitle>
                    </v-list-item>
                    <template v-if="(activeDocument || {}).has_taxes">
                      <v-list-item>
                        <v-list-item-title class="subtitle-2">Neto</v-list-item-title>
                        <v-list-item-subtitle class="text-right grey-500--text font-weight-bold">{{instance.amount_net | currency(((instance || {}).type || ''))}}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item class="mt-3">
                        <v-list-item-title class="subtitle-2">Impuestos ({{activeDocument.tax}}%)</v-list-item-title>
                        <v-list-item-subtitle class="text-right grey-500--text font-weight-bold">{{instance.amount_tax | currency(((instance || {}).type || ''))}}</v-list-item-subtitle>
                      </v-list-item>
                    </template>
                  </template>
                  <v-list-item class="mt-3">
                    <v-list-item-title class="subtitle-2 grey-500--text font-weight-bold subtitle-2">Total documento</v-list-item-title>
                    <v-list-item-subtitle class="text-right subtitle-2 grey-500--text font-weight-bold">{{instance.amount_total}} {{instance?.currency || $store?.state?.auth?.account?.base_currency?.id}}</v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </v-col>
              <!-- end totals -->
            </v-card-text>
          </v-card>
        </div>
        </div>
        <!-- detail card -->

        <!-- panels -->
        <div class="pb-5" style="flex:1; min-width: 180px;">

        <!-- SII / SUNAT -->
          <v-card class="mt-1" flat>
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">
                <v-row align="center" no-gutters>
                  <img class="ml-n1 mr-2" :width="20" :src="require(`@/assets/ax/${$store?.state?.auth?.account?.country === 'PE' ? 'sunat' : 'sii'}--icon.png`)" />
                  {{ $store?.state?.auth?.account?.country === 'PE' ? 'Sunat' : 'Servicio de impuestos internos' }}
                </v-row>
              </v-toolbar-title>
            </v-toolbar>
            <v-divider />
            <v-card-text class="pa-0">
              <div class="pt-4 px-5 pb-2">
                <v-list class="v-list-form-status transparent pa-0">
                  <v-list-item class="pa-0">
                    <v-list-item-title class="grey-500--text subtitle-2 font-weight-bold"><v-icon class="mr-3" size="20" left color="blue-500">mdi-clipboard</v-icon>Estado</v-list-item-title>
                    <v-list-item-subtitle class="body-1">
                      <v-btn v-if="!instance.status || ((instance || {}).status || {}).code === 'IMP'" @click="taxAgencyService('documents', 'documents', 'irs_status')" :loading="taxAgencyLoader" text color="blue-500" :ripple="false">Actualizar estado</v-btn>
                      <span v-else-if="((instance || {}).status || {}).id === 'PDR'" class="body-2 text--disabled d-block" >Sin estado</span>
                      <chip-status v-else :status="((instance || {}).status || {}).code || instance.status" :detail="instance.agency_status || instance.agency_status_info ? `${instance.agency_status} <br> ${instance.agency_status_info}` : null"  :showDetail="true" :isTooltip="false" />
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0" v-if="(instance || {}).agency_status">
                    <v-list-item-title class="grey-500--text subtitle-2 font-weight-bold"><v-icon class="mr-3" size="20" left color="blue-500">mdi-clipboard-search</v-icon>Detalle del estado</v-list-item-title>
                    <v-list-item-subtitle class="body-1 align-self-start mt-2" style="white-space: normal !important">{{instance.agency_status}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0" v-if="(instance || {}).agency_status_info">
                    <v-list-item-title class="grey-500--text subtitle-2"></v-list-item-title>
                    <v-list-item-subtitle class="body-1 align-self-start" style="white-space: normal !important">
                      <!-- <v-tooltip color="grey-background" right transition="scale-transition" max-width="500">
                        <template v-slot:activator="{on}"> v-on="instance.agency_status_info.length >= 256 ? on : false"-->
                          <v-sheet class="grey-300--text" style="justify-content: left;">
                            <span class="">{{instance.agency_status_info.slice(0, 256)}} {{ instance.agency_status_info.length >= 256 ? '...' : ''}}</span>
                          </v-sheet>
                        <!-- </template>
                        <span class="d-block px-3 py-2">{{instance.agency_status_info}}</span>
                      </v-tooltip> -->
                    </v-list-item-subtitle>
                  </v-list-item>
                  <!-- agency_status_detail_list -->
                  <v-list-item class="pa-0" v-if="(instance || {})?.agency_status_detail_list">
                    <v-list-item-title class="grey-500--text subtitle-2"></v-list-item-title>
                    <v-list-item-subtitle class="body-1 align-self-start mt-2" v-if="(instance || {}).agency_status_detail_list" style="white-space: normal !important">
                      <span class="d-block mb-2" v-for="(detail, i) in instance?.agency_status_detail_list.slice(0,2)" :key="i">{{ detail.slice(0, 156) && i === instance.agency_status_detail_list.slice(0,2).length - 1  && detail.length >= 156 ? `${detail}...` : detail}}</span>
                      <v-btn text color="blue-500" v-if="instance.agency_status_detail_list.length >=2" @click="dialogSunat=true">Ver más</v-btn>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0" v-if="$store.state.auth.account.country !== 'PE'">
                    <v-list-item-title class="grey-500--text subtitle-2">Track ID</v-list-item-title>
                    <v-list-item-subtitle class="body-1">
                      <v-tooltip v-if="((instance || {}).status || {}).irs_id" color="grey-background" right transition="scale-transition" max-width="300">
                        <template v-slot:activator="{on}">
                          <v-btn class="grey-300--text" @click="toClipboard(((instance || {}).status || {}).irs_id)" text v-on="on" :ripple="false" style="justify-content: left;">{{((instance || {}).status || {}).irs_id}}</v-btn>
                        </template>
                        <span class="d-block px-3 py-2">Copiar</span>
                      </v-tooltip>
                      <span v-else-if="(((instance || {}).status || {}).id !== 'PDR')"><v-btn @click="taxAgencyService('documents', 'documents', 'irs_send')" :loading="taxAgencyLoader" text color="blue-500" :ripple="false">Enviar a la agencia fiscal</v-btn></span>
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </div>
            </v-card-text>
          </v-card>
          <!-- end SII / SUNAT -->

          <!-- link -->
          <v-card flat class="mt-5" v-if="instance.pdf_link">
            <v-card-text class="px-7 py-6">
              <v-row align="center">
                <v-icon size="18" left color="grey-700">mdi-link-variant</v-icon>
                <v-col class="pa-0 pr-2">
                  <v-text-field v-model="instance.pdf_link" class="publicLink" outlined readonly type="text" hide-details single-line dense prefix="Enlace público:">
                    <template v-slot:append>
                      <v-btn text @click="toClipboard(instance.pdf_link)" class="pl-4 mr-1 body-2" color="blue-500" style="top:-4px">Copiar</v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- end link -->

          <!-- events -->
          <v-card flat class="mt-5">
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold"><v-icon size="18" left color="grey-700">mdi-timeline-clock</v-icon>Eventos</v-toolbar-title>
            </v-toolbar>
            <v-skeleton-loader v-if="eventLoading" class="mb-2 panel px-1 rounded-md" transition="fade-transition" type="list" />
            <template v-else-if="(eventsList || []).length ">
              <v-divider />
              <v-card-text class="pa-0">
                <v-list class="transparent px-0 py-2 dense">
                  <div v-for="(d, i) in events" :key="i">
                    <v-list-item class="px-5 py-0" :key="i">
                      <v-list-item-icon>
                        <v-icon color="blue-500">{{currentEvent(d.event_type).icon}}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="ml-n5">
                        <span class="d-flex grey-700--text body-1">
                          <span class="font-weight-medium">{{currentEvent(d.event_type).summary}}</span>
                          <template v-if="d.description">
                            <v-divider class="mx-2 my-1" vertical />
                            <span class="text-body-2 grey-700--text" v-if="d.description.length < 30" style="margin-top:2px">{{ d.description}}</span>
                            <v-tooltip v-else color="grey-background" right transition="scale-transition" width="500">
                              <template v-slot:activator="{on}">
                                <v-sheet class="grey-700--text" v-on="on" width="100%" height="20">
                                  <span class="d-inline-block text-truncate text-body-2" style="max-width: 90%;margin-top:2px">
                                    {{ d.description }}
                                  </span>
                                </v-sheet>
                              </template>
                              <span class="d-block px-3 py-2">{{d.description}}</span>
                            </v-tooltip>
                          </template>
                        </span>
                        <span class="d-block body-2 grey-300--text">{{d.created | dateTimeSecond}}</span>
                       </v-list-item-title>
                    </v-list-item>
                    <v-sheet class="text-left ml-8 mt-n4 mb-n2" v-if="i !== eventsList.length - 1" height="18">
                      <v-icon size="3" color="placeholder" style="position: absolute; left: 30px">mdi-circle</v-icon>
                      <v-divider vertical class="my-0" />
                      <v-icon class="mb-n1" size="3" color="placeholder" style="left: -1px; top: 7px">mdi-circle</v-icon>
                    </v-sheet>
                    </div>
                  <template v-if="eventsList.length > 3">
                    <v-divider class="mt-4" />
                    <v-btn class="mt-1 mb-n1 mx-5" color="blue-500" @click="showMoreEvents=!showMoreEvents" text>
                      {{ !showMoreEvents ? 'Ver todos los eventos' : 'Ver menos eventos' }} </v-btn>
                  </template>
                </v-list>
              </v-card-text>
            </template>
          </v-card>
          <!-- end events -->

          <!-- related documents -->
          <v-expansion-panels class="mt-5" v-model="panelRelatedDocuments">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-4 py-3">
                <v-row no-gutters align="center" justify="space-between">
                  <span class="subtitle-2 grey-700--text font-weight-bold d-flex align-center"><v-icon size="18" left color="grey-700">mdi-file-document-multiple</v-icon>Referencias</span>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-0">
                <v-divider />
                <v-empty-state class="mt-n2 pb-2" v-if="!references?.length" type="documents" id="documentos" customClass="mt-n5" :isFree="true" title="No hay información de referencias" :height="199" />
                <v-simple-table class="px-0 no-hover table-not-rounded" dense v-else>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left grey-700--text font-weight-medium" style="min-width: 180px;">Tipo de documento</th>
                        <th class="text-right grey-700--text font-weight-medium" style="min-width: 120px;">Serie-Correlativo</th>
                        <th class="text-right grey-700--text font-weight-medium" style="min-width: 70px;">Fecha del doc.</th>
                      </tr>
                      <v-divider style="position: absolute;  margin-top: -10px; min-width: 100%; margin-left: -1px" />
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in references"
                        :key="item.number"
                        style="cursor: pointer"
                        @click="$router.push({ name: 'DocumentsList', params: $route.params, query: { type: item.type, series: item.series, serial: item.serial, _name: instance.number } })"
                      >
                        <td class="body-2">
                          <span class="d-inline-block text-truncate" style="max-width: 99%;" v-if="item.type">{{item.type  | nameDocumentsType}}</span>
                          <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                        </td>
                        <td class="text-right body-2">
                          <span>{{item.number}}</span>
                        </td>
                        <td class="text-right body-2">
                          <span v-if="item.date">{{item.date | date}}</span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        <!-- end related documents -->
        </div>
        <!-- end panels -->
      </div>
    </v-col>
    <!-- dialog grant -->
    <v-dialog v-model="dialogGrant" width="600" persistent no-click-animation scrollable overlay-color="grey-500">
      <v-card class="pa-0">
        <v-toolbar flat height="46" class="transparent">
          <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">Ceder documento</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-3">
              <v-btn @click="dialogGrant=false" icon small retain-focus-on-click><v-icon color="grey-500" size="20">mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-0 py-4">
          <v-row no-gutters>
            <v-col class="pa-0">
              <v-list class="v-list-form-extended transparent pa-0">
                <v-list-item class="px-5 pt-0">
                  <v-list-item-title>
                    <span class="font-weight-bold">Cesionario</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="px-5">
                  <v-list-item-title>{{$t('generals.RUT')}}</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-col cols="6">
                      <v-text-field v-model="$v.grant.tax_id.$model" :error="$v.grant.tax_id.$error" v-dni="'CL'" outlined hide-details required single-line dense maxlength="32" />
                    </v-col>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="py-2 px-5">
                  <v-list-item-title>Nombre</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-text-field v-model="$v.grant.name.$model" :error="$v.grant.name.$error" outlined hide-details required single-line dense maxlength="32" />
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="px-5">
                  <v-list-item-title>Email</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-text-field v-model="$v.grant.email.$model" :error="$v.grant.email.$error" outlined hide-details required single-line dense maxlength="56" />
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="py-2 px-5">
                  <v-list-item-title>Dirección</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-text-field v-model="$v.grant.address.$model" :error="$v.grant.address.$error" outlined hide-details required single-line dense maxlength="36" />
                  </v-list-item-subtitle>
                </v-list-item>
                <v-divider class="my-3" />
                <v-list-item class="px-5">
                  <v-list-item-title>
                    <span class="font-weight-bold">Cedente</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="pt-2 pb-1 px-5">
                  <v-list-item-title>Email</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-text-field v-model="$v.grant.assignor_email.$model" :error="$v.grant.assignor_email.$error" outlined hide-details required single-line dense maxlength="36" />
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer />
          <v-btn @click="dialogGrant=false" outlined>Cancelar</v-btn>
          <v-btn @click="createGrant" :loading="$store.state.irs.createLoader" color="blue-500">Ceder documento</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog grant -->

    <!-- dialog confirm markAsAnulled -->
    <v-dialog v-model="dialogMarkAsAnulled" width="792" persistent no-click-animation overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">Marcar como nulo</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogMarkAsAnulled=false" icon small retain-focus-on-click><v-icon color="grey-500" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 grey-500--text">
          <v-row class="pt-2 pb-4" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-document-null.svg`)" :width="148" />
            </v-col>
            <v-col class="pr-4 pl-4">
              <div class="mt-1 mb-3">
                <v-icon class="mr-2" color="grey-700" size="14">mdi-file-document</v-icon><span class="body-2 font-weight-medium text-uppercase">{{((instance || {}).type || '') | nameDocumentsType}} {{((instance || {}).document_type || '') | nameDocumentsType}} Nº{{instance.number}}</span>
              </div>
              <span class="d-block body-1 pt-2">Al marcar como nulo un documento, sólo lo estás anulando en la plataforma y no en {{$t('generals.SII')}}.</span>
              <span class="d-block body-1 mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogMarkAsAnulled=false" outlined>Cancelar</v-btn>
          <v-btn color="blue-500" @click="markAsAnulled" :loading="loadingAnulled" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm markAsAnulled -->

    <!-- dialog create clone document -->
    <v-dialog v-model="createDocumentDialog" width="520" persistent no-click-animation overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">Crear documento</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="createDocumentDialog=false" icon small retain-focus-on-click><v-icon color="grey-500" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-5 py-4">
          <v-row class="pt-2" align="center">
            <v-col cols="4">
              <img :src="require(`@/assets/backgrounds/modal-document-clone.svg`)" :width="148" />
            </v-col>
            <v-col class="pr-4 pl-4">
              <v-list class="v-list-form pa-0 transparent">
                <v-list-item class="pa-0 mb-4">
                  <v-list-item-content>
                    <v-list-item-title class="grey-500--text body-1 font-weight-semibold">Crear {{ ((DocumentTypesList.find(d => d.id === cloneDoc) || {}).name || '').toLowerCase() }}</v-list-item-title>
                    <v-list-item-subtitle class="mt-1 mb-16">
                      <v-radio-group class="ma-0" v-model="actionCreateDoc" column absolute style="position:absolute" dense hide-details>
                        <v-radio label="Anula documento" value="1" class="mb-0"></v-radio>
                        <v-radio label="Corrige texto documento" value="2" class="mb-0"></v-radio>
                        <v-radio label="Corrige monto documento" value="3"></v-radio>
                      </v-radio-group>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn outlined @click="createDocumentDialog=false" :ripple="false">Cancelar</v-btn>
          <v-btn color="blue-500" :to="{ name: 'DocumentsClone', params: { id: instance.id, ...$route.params }, query: { document_type: cloneDoc, action: actionCreateDoc, ...(instance.type === 'CL39' && { origin: instance.type}) } }" :ripple="false">Crear documento</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog create clone document -->

   <!-- dialog STATUS SUNAT -->
    <v-dialog v-model="dialogSunat" width="790" persistent scrollable no-click-animation overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">Detalle del estado</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogSunat=false" icon small retain-focus-on-click><v-icon color="grey-500" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-5 pt-6 pb-0">
          <div v-for="(detail, i) in instance.agency_status_detail_list" :key="i">
            <span class="d-block mb-8">{{detail}}</span>
            <v-sheet class="text-left ml-1 mt-n8 mb-3" v-if="i !== instance.agency_status_detail_list.length - 1" height="10">
              <v-icon class="mt-1" size="3" color="placeholder" style="position: absolute; left: 22.5px;">mdi-circle</v-icon>
              <v-divider vertical class="my-0" />
              <v-icon class="mb-n1" size="3" color="placeholder" style="left: -0.4px; top: 7px">mdi-circle</v-icon>
            </v-sheet>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn color="blue-500" @click="dialogSunat=false" :ripple="false">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog STATUS SUNAT -->

    <!-- dialog confirm reprocesar -->
    <v-dialog v-model="dialogReprocessDoc" width="792" persistent no-click-animation overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">Re-procesar documento</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogReprocessDoc=false" icon small retain-focus-on-click><v-icon color="grey-500" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 grey-500--text">
          <v-row class="pt-2 pb-4" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-document-null.svg`)" :width="148" />
            </v-col>
            <v-col class="pr-4 pl-4">
              <div class="mt-1 mb-3">
                <v-icon class="mr-2" color="grey-700" size="14">mdi-file-document</v-icon><span class="body-2 font-weight-medium text-uppercase">{{((instance || {}).type || '') | nameDocumentsType}} {{((instance || {}).document_type || '') | nameDocumentsType}} Nº{{instance.number}}</span>
              </div>
              <span class="d-block body-1 pt-2">Al confirmar, se enviará nuevamente este documento a la {{$t('generals.SII')}} para su revisión y aceptación. Esta acción no se puede deshacer.</span>
              <span class="d-block body-1 mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogReprocessDoc=false" outlined>Cancelar</v-btn>
          <v-btn color="blue-500" @click="reprocessDoc" :loading="loadingReprocessDoc" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm reprocesar -->

    <!-- dialog pdf -->
    <v-dialog v-model="modalPDF" width="700" persistent :scrollable="true" no-click-animation overlay-color="grey-500">
      <render-pdf :basicRender="true" :documentType="((instance || {}).type || '') | nameDocumentsType" :number="instance.number" @close="modalPDF=false" :loaderRender="loaderRender" :renderUrl="renderUrl" :flat="true" :formatPDF.sync="formatPDF" :showFormat="true"  />
    </v-dialog>
    <!-- end dialog pdf -->

    <!-- dialog send email new ui -->
    <v-dialog v-model="modalEmail" width="700" persistent no-click-animation overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar class="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">Enviar por mail</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="modalEmail=false" icon small retain-focus-on-click><v-icon color="grey-500" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-5 py-4">
          <v-list class="v-list-form pa-0 transparent">
          </v-list>
          <div class="d-flex align-center">
            <span class="grey-500--text body-1" style="min-width: 60px;">Para</span>
            <v-combobox class="a-combobox" ref="email" v-model="messages.recipient" :key="key" hide-details :items="emailsList" outlined required hide-selected :placeholder="!messages.recipient.length ? 'Escribe el email del destinatario' : ''" @update:search-input="setEmail"  multiple deletable-chips small-chips dense single-line autocomplete="off">
              <template v-slot:selection="data">
                <v-chip class="pr-2 ma-1 close-chip" color="blue-100" v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="remove(data.item)" small>{{ data.item }}</v-chip>
              </template>
              <template v-slot:item="data">
                <v-list-item-avatar>
                  <v-icon color="grey-700">mdi-account-circle</v-icon>
                </v-list-item-avatar>
                <v-list-item-title class="ml-n2 font-weight-medium" v-html="data.item"></v-list-item-title>
              </template>
            </v-combobox>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="modalEmail=false" outlined>Cancelar</v-btn>
          <v-btn @click="createMessages" :loading="$store.state.messages.createLoader" color="blue-500">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog -->
  </v-row>
</template>

<script>
  import { mapState } from 'vuex'
  import MiniHeader from '@/components/commons/MiniHeader'
  import { email, minLength, maxLength, required } from 'vuelidate/lib/validators'
  import { rutValidator } from 'vue-dni'
  import ChipStatus from '@/components/commons/ChipStatus'
  import VModal from '@/components/commons/VPanel'
  import SkeletonDetail from '@/components/commons/skeletonLoader/SkeletonDetail'
  import RenderPdf from '@/components/files/RenderPdf'
  import DocumentsList from '@/modules/documents/components/DocumentsList'
  import DocumentEvents from '@/collections/documentEvents'
  import GenericViewMixin from '@/mixins/GenericViewMixin'
  import RenderViewMixin from '@/mixins/RenderViewMixin'
  import TaxAgencyViewMixin from '@/mixins/TaxAgencyViewMixin'
  import DocumentTypes from '@/collections/documentTypes'
  import numeral from 'numeral'
  import moment from 'moment'
  import VEmptyState from '@/components/commons/VEmptyState'
  import cloneDeep from 'lodash/cloneDeep'
  const REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

  export default {
    mixins: [
      GenericViewMixin,
      RenderViewMixin,
      TaxAgencyViewMixin
    ],
    components: {
      VEmptyState,
      ChipStatus,
      VModal,
      SkeletonDetail,
      RenderPdf,
      DocumentsList,
      MiniHeader
    },
    data: () => ({
      references: [],
      key: 0,
      emailsList: [],
      dialogReprocessDoc: false,
      loadingReprocessDoc: false,
      dialogSunat: false,
      link: 'https://docs.axteroid.com/',
      eventLoading: false,
      // events: [],
      showMoreEvents: false,
      confirm: '',
      dialogMarkAsAnulled: false,
      loadingAnulled: false,
      actionCreateDoc: '',
      cloneDoc: '',
      createDocumentDialog: false,
      formatPDF: 1,
      loadingDetail: false,
      loading: false,
      scroll: 0,
      activePanel: false,
      grant: {
        tax_id: '',
        name: '',
        address: '',
        email: '',
        assignor_email: ''
      },
      dialogGrant: false,
      dialogCreditNote: false,
      dialogMessages: false,
      loadingDocumentsDuplicate: false,
      panelIrsSii: [],
      panelRelatedDocuments: false,
      panelTotals: false,
      modalEmail: false,
      panelComments: false,
      modalPDF: false,
      comments: '',
      messages: {
        text: '',
        recipient: '',
        subject: '',
        type: ['pdf', 'xml']
      },
      env: process.env?.VUE_APP_MODE ?? 'ax',
      documentsCanBeCreated: {
        CL33: ['CL61', 'CL56'],
        CL61: ['CL56'],
        CL39: ['CL61', 'CL56'],
        CL110: ['CL112', 'CL111'],
        CL111: ['CL112'],
        CL34: ['CL61', 'CL56'],
        CL41: ['CL61', 'CL56'],
        CL112: ['CL111'],
        CL56: ['CL61'],
        CL46: ['CL61', 'CL56']
      },
      DocumentTypesList: DocumentTypes,
      breadcrumbs: {
        main: 'Emisión',
        children: [
          {
            text: 'Documentos',
            route: 'DocumentsList'
          }
        ]
      }
    }),
    computed: {
      ...mapState({
        instance: state => state.documents.documentsDetail,
        supplierresponsesList: state => state.irs.supplierresponsesList,
        creditnotesList: state => state.documents.creditnotesList,
        debitnotesList: state => state.documents.debitnotesList,
        eventsList: state => state.documents.eventsList,
        contactsList: state => state.customers.contactsList,
        count: state => state.documents.documentsCount,
        documentsList: state => state.documents.documentsList
      }),
      totalCredits () {
        return this.sumProperty(this.creditnotesList, 'total')
      },
      totalDebits () {
        return this.sumProperty(this.debitnotesList, 'total')
      },
      totalAdjustedDocuments () {
        return this.instance.total - this.totalCredits + this.totalDebits
      },
      totalPaid () {
        return this.instance.payments.length ? this.instance.payments.map(c => c.amount_paid).reduce((a, b) => a + b) : 0
      },
      activeDocument () {
        return DocumentTypes.find((d) => d.id === this.instance.type)
      },
      checkPermissionDTE () {
       return (dte) => {
          if (!this.$store.state.accounts.me?.is_owner && !this.$store.state.auth.userAccount.is_staff) {
            /* eslint-disable */
            const ownGroupPermissions = [...(this.$store.state.accounts.me?.document_type_permissions ?? []), ...(this.$store.state.accounts.me?.groups?.map(({document_type_permissions}) => document_type_permissions)[0] ?? [])]
            return ownGroupPermissions.filter((item) => item.document_type === dte.id)
          }
          return true
        }
      },
      events () {
        return !this.showMoreEvents ? this.eventsList.slice(0,3) : this.eventsList
      },
      currentEvent () {
        return (val) => {
          return DocumentEvents.find(({id}) => val === id) ?? {}
        }
      },
      currentParams () {
        let params = JSON.parse(JSON.stringify(this.$route.params))
        delete params.id
        return params
      }
    },
    watch: {
      panelRelatedDocuments (val) {
        if (val === 0) this.getList()
      },
      modalPDF (val) {
        this.modalRender = val
        if (!this.renderUrl) this.renderDocument2('documents')
        this.formatPDF = 1
      }
    },
    created () {
      this.activePanel = true
      this.retrieve()
    },
    methods: {
      setEmail (e) {
        let array = []
        this.emailsList = []

        if (e?.length) {
          let elm = e.replace(/ /g, ',')
          array = [...array, ...elm.split(',')]
          array = array.filter((item) => Boolean(item))

          if (array.length === 1) {
            if (REGEX.test(e)) {
              this.emailsList.push(e)
              this.emailsList = [...new Set([...this.emailsList])]
            }
          } else {
            this.key += 1
            this.messages.recipient = [...this.messages.recipient, ...array.filter(item => REGEX.test(item))]
            this.messages.recipient = [...new Set(this.messages.recipient)]
          }
        }
      }, 
      remove (item) {
        const index = this.messages.recipient.indexOf(item)
        if (index >= 0) {
          this.messages.recipient.splice(index, 1)
        }
      },
      onScroll (e) {
        this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
      },
      reprocessDoc () {
        this.loadingReprocessDoc = true
        this.$store.dispatch('documents/GET', {
          resource: `documents/${this.$route.params.id}/reprocess`
        })
        .then(() => {
          this.$dialog.message.info('El documento fue reprocesado con éxito.')

          setTimeout(() => {
            this.retrieve()
          }, 600)
        })
        .catch((error) => {
          this.$dialog.message.error(error.response.data.message)
        })
        .finally(() => {
          this.confirm = ''
          this.loadingReprocessDoc = false
          this.dialogReprocessDoc = false
        })
      },
      markAsAnulled () {
        this.loadingAnulled = true
        this.$store.dispatch('documents/GET', {
          resource: `documents/${this.$route.params.id}/void`
        })
        .then(() => {
          this.$dialog.message.info('El documento se marco como nulo con exito')

          setTimeout(() => {
            this.retrieve()
          }, 600)
        })
        .catch((error) => {
          this.$dialog.message.error(error.response.data.message)
        })
        .finally(() => {
          this.confirm = ''
          this.loadingAnulled = false
          this.dialogMarkAsAnulled = false
        })
      },
      handlerClone (doc = '') {
        this.createDocumentDialog = true
        this.cloneDoc = doc
      },
      async getList () {
        this.loading = true
        await this.$store.dispatch('documents/LIST', {
          resource: 'documents',
          query: {
            documents_related: this.instance.id,
            page_size: 6
          }
        })
        this.loading = false
      },
      retrieve () {
        this.loadingDetail = true
        this.$store.dispatch('documents/RETRIEVE', {
          resource: 'documents',
          id: this.$route.params.id
        })
        .then((response) => {
          this.references = response.data.references
          this.eventLoading = true
          this.$store.dispatch('documents/LIST', {
            resource: `documents/${this.instance.id}/events`
          })
          this.messages.subject = `Envía ${this.activeDocument?.name ?? ''} N° ${this.instance.number}`
          this.messages.text = `Estimado cliente, ${this.instance?.customer_name ?? ''},\n\n`
          this.messages.text += `Adjunto ${this.activeDocument?.name ?? ''} N° ${this.instance.number}, `
          this.messages.text += `correspondiente al ${moment(this.instance?.date).format('DD MMM YYYY')} `
          this.messages.text += `por un monto de ${numeral(this.instance?.amount_total).format('0,0')} ${this.instance?.exchange?.currency_from ?? this.instance?.exchange?.currency_to ?? this.activeDocument?.currencies[0] ?? ''} \n\nAtentamente ${this.account?.name}`
        })
        .finally(() => {
          this.eventLoading = false
          this.loadingDetail = false
        })
      },
      createCreditNote () {
        this.$v.creditNote.$touch()
        if (this.$v.creditNote.$invalid) {
          return false
        }
        this.$store.dispatch('documents/CREATE', {
          resource: 'creditnotes',
          payload: {
            documents: this.instance.id,
            note: this.creditNote.note
          }
        })
        .then(() => {
          this.dialogCreditNote = false
          this.retrieve()
        })
        .catch((error) => {
          this.$dialog.message.error(error.response.data.message)
        })
      },
      createMessages () {
        this.messages.document = this.instance.id
        this.messages.type = this.messages.type.length === 2 ? 'all' : this.messages.type.toString()

        this.$v.messages.$touch()
        if (this.$v.messages.$invalid) {
          return false
        }
        this.$store.dispatch('messages/CREATE', {
        resource: 'documents/distribute',
        query: this.$route.query,
        payload: {
          document_ids: [this.$route.params.id],
          email:  this.messages.recipient.toString()
        }
      })
      .then(() => {
        this.$dialog.message.info('El envío de documentos por email se realizó con éxito')
        this.messages.recipient = ''
        this.messages.type = []
      })
      .catch((error) => {
        this.$dialog.message.error(error.response.data.message)
      })
        .finally(() => {
          this.modalEmail = false
        })
      },
      createExport () {
        this.$store.dispatch('accounts/DOWNLOAD', {
          resource: 'documents/download',
          query: this.$route.query,
          payload: {
            document_ids: [this.$route.params.id]
          }
        })
        .then(() => {
          this.$dialog.message.info('La descarga se ha realizado con éxito.')
        })
        .catch(() => {
          this.$dialog.message.error('Ha ocurrido un error en el proceso de descarga')
        })
    },
      createGrant () {
        this.$v.grant.$touch()
        if (this.$v.grant.$invalid) {
          return false
        }
        this.grant.documents = this.instance.document
        this.$store.dispatch('irs/CREATE', {
          resource: 'irs/sii/grants',
          payload: { ...this.grant, ...{document: this.$route.params.id} }
        })
        .then((response) => {
          this.grant = response.data
          this.dialogGrant = false
        })
        .catch((error) => {
          this.$dialog.message.error(error.response.data.message)
        })
      },
      cloneDocuments () {
        const dueDate = moment(this.today).add(30, 'days').format('YYYY-MM-DD')
        const payload = cloneDeep(this.instance)
        delete payload.id
        delete payload.imported
        delete payload.local_file
        delete payload.metadata
        delete payload.number
        delete payload.status
        delete payload.subscription
        delete payload.irs
        delete payload.test_mode
        delete payload.created
        delete payload.updated
        payload.collection_method = 'deferred'
        payload.customer = this.instance.customer.id
        payload.date = this.today
        payload.document = this.instance.document.id
        payload.due_date = dueDate
        payload.lines.map((line) => {
          Object.keys(line).forEach((key) => {
            if (['created', 'id', 'documents', 'updated', 'total', 'total_adjusted'].includes(key)) {
              delete line[key]
            }
            if (!line.discount) {
              line.discount = 0
              line.discount_scheme = '%'
            }
          })
          return line
        })
        payload.payments = [{
          amount: this.instance.total,
          date: dueDate,
          description: 'Pago de factura',
          currency: this.instance.type
        }]
        this.loadingDocumentsDuplicate = true
        this.$store.dispatch('documents/CREATE', {
          resource: 'documents',
          payload: payload
        })
        .then((response) => {
          this.$router.push({ name: 'DocumentsRetrieve', params: {id: response.data.id, ...this.$route.params} })
        })
        .catch((error) => {
          this.$dialog.message.error(error.response.data.message)
        })
        .finally(() => {
          this.loadingDocumentsDuplicate = false
        })
      },
      builtInSupllierResponses () {
        this.$store.dispatch('irs/CREATE', {
          resource: 'irs/sii/supplierresponses',
          payload: {
            documents: this.instance.id
          }
        })
        .catch((error) => {
          this.$dialog.message.error(error.response.data.message)
        })
      },
      updateCollectible () {
        let dialogTitle = 'Marcar como incobrable'
        let dialogMsg = 'Esta factura no será considerada como pendiente de pago, pero será posible pagarla si el cliente lo intenta.'
        let successMsg = 'La factura se marcó como incobrable'
        if (this.instance.status === 'uncollectible') {
          dialogTitle = 'Marcar como cobrable'
          dialogMsg = 'Esta factura volverá a ser considerada como pendiente de pago.'
          successMsg = 'La factura se marcó como cobrable'
        }
        this.$dialog.warning({
          title: dialogTitle,
          text: `
            <span class="d-block mb-4">${dialogMsg}</span>
            <span class="d-block">¿Estás seguro de continuar?</span>
          `,
          actions: [
            {
              color: 'red',
              text: 'Continuar',
              handle: () => {
                this.$store.dispatch('documents/RETRIEVE', {
                  resource: 'documents',
                  id: this.$route.params.id,
                  detail: 'uncollectible',
                  loader: false
                })
                .then(() => {
                  this.$dialog.message.info(successMsg)
                })
                .catch((error) => {
                  this.$dialog.message.error(error.response.data.message)
                })
              }
            }
          ]
        })
      }
    },
    validations: {
      messages: {
        recipient: {
          required
        },
        subject: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(60)
        },
        text: {
          required
        }
      },
      grant: {
        tax_id: {
          required,
          maxLength: maxLength(32),
          rutValidator
        },
        name: {
          required,
          maxLength: maxLength(32)
        },
        address: {
          required,
          maxLength: maxLength(56)
        },
        email: {
          required,
          email,
          maxLength: maxLength(36)
        },
        assignor_email: {
          required,
          email,
          maxLength: maxLength(36)
        }
      }
    }
  }
</script>
<style>
.textarea-email.v-text-field--enclosed .v-input__append-inner {
  align-self: end;
  padding: 10px 0;
}
</style>